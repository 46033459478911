<template>
  <v-card>
    <v-card-title class="text-h6">
      <div>
        <v-row>
          <v-col>
            <v-btn
              :color="tab === 'sync' ? 'primary' : 'default'"
              block
              @click="changeTab('sync')"
            >
              {{ $t("labels.sync_available_e_market") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :color="tab === 'config-goods' ? 'primary' : 'default'"
              block
              @click="changeTab('config-goods')"
            >
              {{ $t("labels.config_sync_goods_stock") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :color="tab === 'config-combo' ? 'primary' : 'default'"
              block
              @click="changeTab('config-combo')"
            >
              {{ $t("labels.config_sync_combo_stock") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :color="tab === 'generate-sku' ? 'primary' : 'default'"
              block
              @click="changeTab('generate-sku')"
            >
              {{ $t("labels.generate_e_market_seller_sku") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text v-if="tab == 'sync'">
      <div style="height: calc(100vh - 344px)" class="pt-10">
        <v-row>
          <v-col cols="12" md="3">
            <SelectEMarket
              class="c-input-small"
              :label="$t('labels.e_market')"
              :placeholder="$t('labels.e_market')"
              name="id_e_market"
              @onFilter="onFilterChange"
            />
          </v-col>
          <v-col cols="12" md="3">
            <SelectEMarketShop
              class="c-input-small"
              :label="$t('labels.shop')"
              :placeholder="$t('labels.shop')"
              name="id_e_market_shop"
              :filters="syncItem"
              @onFilter="onFilterChange"
              @itemSyncStock="onItemChange"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              class="c-input-small"
              v-model="syncItem.type"
              :items="syncTypes"
              :label="$t('labels.type')"
              dense
              outlined
              clearable
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col>
                <v-btn
                  color="success"
                  small
                  block
                  outlined
                  @click="reSync"
                  :disabled="!syncItem.id_e_market_shop || !isShopSyncStock"
                  class="mr-1"
                  style="height: 40px"
                >
                  <v-icon>mdi-sync</v-icon> {{ $t("labels.re_sync_sku") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  small
                  block
                  outlined
                  @click="confirm"
                  :disabled="!syncItem.id_e_market_shop"
                  style="height: 40px"
                >
                  <v-icon>mdi-download</v-icon> {{ $t("labels.download_file") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              :disabled="syncItem.type != 4"
              v-model="syncItem.barcodes"
              dense
              outlined
              clearable
              hide-details
              :label="$t('labels.config_sync_stock_label')"
              class="c-input-small"
              @paste.prevent.stop="onBarcodePaste"
              :placeholder="$t('labels.config_sync_stock_placeholder')"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-card-text v-if="tab == 'config-goods'">
      <EMarketGoodsConfig />
    </v-card-text>

    <v-card-text v-if="tab == 'config-combo'">
      <EMarketComboConfig />
    </v-card-text>

    <v-card-text v-if="tab == 'generate-sku'">
      <EMarketGenerateSku />
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "EMarketSkuQuantity",
  components: {
    SelectEMarket: () => import("@/components/common/SelectEMarket"),
    SelectEMarketShop: () => import("@/components/common/SelectEMarketShop"),
    EMarketGoodsConfig: () => import("@/components/goods/EMarketGoodsConfig"),
    EMarketComboConfig: () => import("@/components/goods/EMarketComboConfig"),
    EMarketGenerateSku: () => import("@/components/goods/EMarketGenerateSku"),
  },
  props: {},
  data: () => ({
    isLoading: false,
    tab: "sync",
    isShopSyncStock: 0,
    syncItem: {},
    syncTypes: [],
    eMarketOptions: [],
    shops: [],
  }),
  computed: {},
  created() {
    this.syncTypes = [
      {
        text: this.$t("labels.sync_all"),
        value: 1,
      },
      {
        text: this.$t("labels.sync_goods"),
        value: 2,
      },
      {
        text: this.$t("labels.sync_combo"),
        value: 3,
      },
      {
        text: this.$t("labels.sync_by_code"),
        value: 4,
      },
    ];
  },
  mounted() {
    // this.getEMarket();
    // this.getShop();
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    onBarcodePaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const barcodeSplit = value.split("\n").filter((v) => v);
        this.syncItem = { ...this.syncItem, barcodes: barcodeSplit.join(";") };
      } else if (value.includes(" ")) {
        const barcodeSplit = value.split(" ").filter((v) => v);
        this.syncItem = { ...this.syncItem, barcodes: barcodeSplit.join(";") };
      } else {
        this.syncItem = { ...this.syncItem, barcodes: value };
      }
    },

    onFilterChange(filter) {
      this.syncItem = { ...this.syncItem, [filter.name]: filter.value };
    },

    onItemChange(val) {
      this.isShopSyncStock = val;
    },

    cancel() {
      this.$emit("cancel");
    },

    async reSync() {
      if (!this.syncItem.id_e_market_shop || !this.isShopSyncStock) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/re-sync-sku-e-market", {
          id_shop: this.syncItem.id_e_market_shop,
          barcodes: this.syncItem.barcodes,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.sync_stock_loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-hang-hoa-va-sl-kha-dung.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-export-sku-quantity",
          { ...this.syncItem },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async getEMarket() {
      const { data } = await httpClient.post("/list-e-market");
      this.eMarketOptions = [...data].map((e) => ({
        value: e.id,
        text: e.full_name || "",
      }));
    },

    async getShop() {
      try {
        const { data } = await httpClient.post(
          "/get-all-e-market-shop",
          this.filters
        );
        this.shops = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    eMarketShopOptions(item) {
      if (!item.id_e_marketplace) {
        return [];
      }
      return this.shops
        .filter(
          (shop) =>
            shop.id_e_marketplace == item.id_e_marketplace && shop.active == 1
        )
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
};
</script>

<style scoped></style>
